import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    button: {
        borderRadius: 55,
        fontSize: '16px',
        textTransform: 'none',
		fontWeight: 'normal'
    },
    disabled: {
        '& path': {
            fill: 'rgba(0, 0, 0, 0.26) !important'
        }
    }
}));