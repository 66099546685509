export function currencyString(value: any) {
    const _value = !isNaN(value) ? Math.round(value) : value
    const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', });
    return currencyFormat.format(_value || 0).split('.')[0];
}

export function parsePercentage(value: any, fractionDigits: number = 1) {
    if (!value) return '0%';
    const percent = (parseFloat(value || 0) * 100)?.toFixed(fractionDigits);
    return `${fractionDigits === 1 ? percent.replace('.0', '') : percent}%`;
}