import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "40px 0",
		border: '1px solid #CFD5E2',
		borderRadius: 8,
		display: 'grid',
		gridTemplateColumns: "50% 50%"
	},
	description: {
		maxHeight: "calc(3*20px)"
	},
	tag: {
		color: theme.palette.primary.main,
		fontSize: 12,
		textTransform: 'uppercase'
	},

}))