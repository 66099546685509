import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 1080,
		height: 200
	},
	textContainer: {
		maxWidth: 912,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 33,
	},
	logo: {
		height: 36,
		width: 137,
		[theme.breakpoints.down('sm')]: {
			height: 21,
			width: 83,
		}
	},
	text: {
		textAlign: 'center',
		fontSize: 20,
		lineHeight: '32px',
		fontStyle: 'italic',
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '24px',
			maxWidth: 295
		}
	},
	quotationMark: {
		marginTop: 25,
		width: 78,
		height: 49,
		[theme.breakpoints.down('sm')]: {
			width: 26,
			height: 20,
			marginTop: 0,
			paddingRight: 5
		}

	},
	quotationInverted: {
		transform: "rotate(-180deg)",
		marginTop: 55,
		width: 78,
		height: 49,
		[theme.breakpoints.down('sm')]: {
			width: 26,
			height: 20,
			marginTop: 0,
			paddingRight: 5

		}
	},
	quotationMarkContainer: {
		[theme.breakpoints.down(1024)]: {
			display: 'none'
		}
	},
	quotationMarkSmall: {
		[theme.breakpoints.up(1024)]: {
			display: 'none'
		}
	},
	link: {
		textDecoration: 'none',
		"&:active": {
			textDecoration: 'none',
		},
		"&:hover": {
			textDecoration: 'none',
		}
	}
}))