import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateColumns: 'calc(100% - 588px) 588px',
		backgroundColor: "rgba(117, 139, 183, 0.04)",
		[theme.breakpoints.down('md')]: {
			gridTemplateColumns: '100%',
		}
	},
	informationContainer: {
		padding: '115px 70px',
		[theme.breakpoints.down('md')]: {
			padding: '40px 16px',

		}
	},
	dateAndAuthorContainer: {
		marginBottom: 24,

	},
	dateAndAuthor: {
		color: '#8892A0',
		fontSize: 18,
		lineHeight: '23px',
		[theme.breakpoints.down('md')]: {
			fontSize: 14,
			lineHeight: "18px",

		}
	},
	title: {
		fontWeight: "bold",
		fontSize: 60,
		lineHeight: "76px",
		marginBottom: 24,
		[theme.breakpoints.down('md')]: {
			fontSize: 32,
			lineHeight: "42px",

		}

	},
	description: {
		fontSize: 18,
		lineHeight: '23px',
	},
	socialLinkContainer: {
		marginTop: 14,
		gap: 16,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			justifyContent: 'left',
			marginTop: 0,

		},
	},
	socialLink: {
		background: '#E8F7F0',
		borderRadius: '50%',
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 4,
		boxSizing: 'border-box',
		[theme.breakpoints.down('md')]: {
			width: 32,
			height: 32,
		}
	},
}))