import { Grid, Typography } from "@material-ui/core";
import React from 'react'
import { useStyles } from "./Tags.styles";
export function Tags() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid item>
				<div className={classes.tag}>
					<Typography>
						<span>Inmobiliario</span>
					</Typography>
				</div>
			</Grid>
			<Grid item>
				<div className={classes.tag}>
					<Typography>
						<span>Créditos</span>
					</Typography>
				</div>
			</Grid>
			<Grid item>
				<div className={classes.tag}>
					<Typography>
						<span>Hipotecas</span>
					</Typography>
				</div>
			</Grid>
		</Grid>
	)
}