import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        defaultTitleAlt: titleAlt
        shortName
        author
        siteLanguage
        logo
        siteUrl: url
        pathPrefix
        defaultDescription: description
        defaultBanner: banner
        twitter
        defaultKeywords: keywords
      }
    }
  }
`;

export function useGetSite() {
	const data = useStaticQuery(query)
	return data
}