import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '100%',
		height: "calc(100vh - 50px)",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		overflow: 'hidden',
		backgroundColor: 'rgba(249, 250, 249, 0.6)',
		[theme.breakpoints.down('md')]: {
			// padding: '0px 24px',
			alignItems: 'center',
			height: 'calc(100vh - 50px )',

		}
	},
	data: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: -80,
		// justifyContent: 'center',
		// marginTop: '6%',
		width: 607,
		'& > h3': {
			fontSize: 34,
			fontWeight: 'bold',
			color: theme.palette.primary.main,
			margin: '12px 0px 3px'
		},
		'& > p': {
			maxWidth: 440,
			textAlign: 'center',
			marginBottom: 30
		},
		[theme.breakpoints.down('sm')]: {
			'& > h3': {
				fontSize: 24,
			},
			maxWidth: '100%',
			// marginTop: '-80px',
			//top: '15%'
		},
	},
	backgroundTop: {
		position: 'absolute',
		top: 0,
		left: 0
	},
	backgroundBottom: {
		position: 'absolute',
		bottom: 0,
		right: 0
	}
}));