import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./Hero.styles";
export function HeroBlog(){
	const classes = useStyles()
	return(
		<Grid container className={classes.root}>
			<Grid item>
				<Typography color="primary" className={classes.title}>
					Tips y tutoriales sobre
					créditos hipotecarios
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.subtitle}>
					¡Te ayudamos con información actualizada y al alcance de un clic!
				</Typography>
			</Grid>
		</Grid>	
	)
}