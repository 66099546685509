import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "40px 72px",
		[theme.breakpoints.down('sm')]: {
			padding: "24px 16px",

		}
	},
	card: {
		minHeight: 475,
		maxHeight: 500,
		"&:hover $readContainer": {
			display: 'flex',

		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'

		}
	},
	title: {
		fontSize: 20,
		lineHeight: '24px',
		fontWeight: 'bold',
		marginBottom: 8,
	},
	containerPosts: {
		display: 'grid',
		gridTemplateColumns: "repeat(3, 408px)",
		gap: 40,
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: "100%",

		}
	},
	informationContainer: {
		padding: 24,
		minWidth: "100%"
	},
	dateAndAuthor: {
		color: '#8892A0',
		fontSize: 18,
		lineHeight: '23px',
		marginBottom: 30,
	},
	titlePost: {
		fontWeight: "bold",
		fontSize: 28,
		lineHeight: "35px",
		marginBottom: 8

	},
	description: {
		fontSize: 18,
		lineHeight: '23px',
		marginBottom: 24,
		maxHeight: '68px',
		overflow: 'hidden'
	},
	tag: {
		color: theme.palette.primary.main,
		fontSize: 12,
		textTransform: 'uppercase'
	},
	readContainer: {
		display: 'none',
	},
	read: {
		color: theme.palette.primary.main,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	},
	featuredImage: {
		width: 408,
		height: 250,
		objectFit: 'cover'
	}
}))