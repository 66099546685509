import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./FAQ.styles";
import { FAQAccordion } from "./FAQAccordionItem";
import { motion } from "framer-motion";
import clsx from "clsx";
import { Link } from "../Link";

export function FAQ() {
	const classes = useStyles()

	return (
		<Grid
			container
			className={classes.root}
			id="FAQ"
		>
			<div
				data-sal="slide-up"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Grid
					item
					container
					justifyContent="center"
				>
					<Typography
						color="primary"
						className={classes.title}
						component="h2"
					>
						Dudas que podrías tener
					</Typography>
				</Grid>
				<Grid
					item
					container
					className={clsx(classes.FAQColumnsContainer)}
					initial={{ opacity: 0, y: 100 }}
					transition={{ duration: 1.1 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					component={motion.div}
				>
					<FAQAccordion question="¿Cuál es la diferencia entre Kredi y un banco tradicional?">
						<ul className={classes.list}>
							<li><Typography>Con Kredi tienes <strong>mayores probabilidades de obtener tu crédito, </strong> ya que somos un poco más flexibles que los bancos tradicionales con algunos requisitos.</Typography></li>
							<li><Typography>Nuestro proceso es <strong>95% en línea,</strong> mientras que con un banco tendrás que hacer todo el papeleo de forma presencial.</Typography></li>
							<li><Typography>Con nosotros <strong>el trámite es mucho más rápido </strong> que con los bancos tradicionales, por lo que vas a ahorrarte mucho tiempo.</Typography></li>
						</ul>
					</FAQAccordion>

					<FAQAccordion question="¿Cómo se que Kredi es un sitio seguro?">
						<Typography className={classes.answer}>
							Ten la tranquilidad de que a nosotros nos supervisa y nos regula la <strong>CONDUSEF</strong> y la <strong>Comisión Nacional Bancaria y de Valores,</strong> por lo que cumplimos con todas las leyes y requisitos para poder operar. Además nuestros inversionistas incluyen a Y Combinator (quien ha invertido en empresas como <strong>Airbnb, Stripe, Dropbox</strong> y<strong> Rappi</strong>) y a Harvard Management Company.
						</Typography>
					</FAQAccordion>

					<FAQAccordion question="¿Necesito poder comprobar mis ingresos?">
						<Typography className={classes.answer}>
							Sí, aunque somos flexibles con muchas de nuestras políticas, la comprobación de ingresos es algo que sí requerimos de nuestros clientes. Puedes usar tus recibos de nómina o tus estados de cuenta. Damos más detalles sobre esto en la <strong>pregunta de requisitos.</strong>
						</Typography>
					</FAQAccordion>

					<FAQAccordion question="¿Qué documentos necesito para iniciar mi trámite con Kredi?">
						<ul className={classes.list}>
							<li >
								<Typography className={classes.answer}> Identificación oficial vigente (INE o Pasaporte). </Typography>
							</li>
							<li >
								<Typography className={classes.answer}> Comprobante de domicilio. </Typography>
							</li>
							<li >
								<Typography className={classes.answer}> Últimos 3 meses de tu recibo de nómina si eres empleado con sueldo fijo. </Typography>
							</li>
							<li >
								<Typography className={classes.answer}> Comprobante de ingresos según tu perfil: nominhabiente, independiente. </Typography>
							</li>
							<li>
								<Typography className={classes.answer}> 6 meses de estados de cuenta bancarios, si eres independiente o si parte de tu sueldo es variable. </Typography>
							</li>

						</ul>
					</FAQAccordion>

					<FAQAccordion question="¿Cómo es el proceso con Kredi?">
						<div className={classes.krediProccessQ}>
							<Typography className={classes.answer}>El proceso para obtener tu crédito hipotecario se divide en 3 etapas:</Typography>
							<ol className={classes.list}>
								<li><Typography className={classes.answer}>Precalificación</Typography></li>
								<li><Typography className={classes.answer}>Autorización</Typography></li>
								<li><Typography className={classes.answer}>Formalización</Typography></li>
							</ol>
							<Typography className={classes.answer}>
								La duración del proceso depende mucho de que tan rápido nos envíes tu documentación. Si quieres más información del proceso haz <Link to="/como-funciona">clic aquí.</Link>
							</Typography>

						</div>
					</FAQAccordion>

					<FAQAccordion question="¿Cuánto es el aforo máximo que me pueden prestar?">
						<Typography className={classes.answer}>
							El aforo es la relación entre el monto del crédito y el valor de la vivienda.El aforo máximo que se puede autorizar es del 90% para personas asalariadas y 85% para personas físicas con actividad empresarial.
						</Typography>

					</FAQAccordion>


				</Grid>
			</div>
		</Grid>
	)
}