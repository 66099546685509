export interface MetaData {
	title?: string;
	titleAlt?: string;
	description?: string;
	image?: string;
	url?: string;
	keywords?: string;
}

export function getMetaData(pathname: string, defaultMeta: MetaData): MetaData {
	const _meta = { ...defaultMeta };
	if (pathname.includes('/landing/')) {
		const landingName = pathname.split('/')[2];
		switch (landingName) {
			default: break;
			case 'adquisicion-de-vivienda': {
				_meta.title = 'Kredi | Simulador de credito hipotecario';
				_meta.titleAlt = 'Kredi | Simulador de credito hipotecario';
				_meta.description = 'Simulador de crédito hipotecario, tu préstamo para comprar casa o departamento, fácil, rápido y sin complicaciones.';
				break;
			}
		}
	} else {
		const homePageName = pathname.split('/')[1];
		switch (homePageName) {
			default: break;
			case 'sobre-nosotros': {
				_meta.title = 'Kredi | La historia detrás del crédito hipotecario sin complicaciones';
				_meta.titleAlt = 'Kredi | La historia detrás del crédito hipotecario sin complicaciones';
				_meta.description = 'En Kredi estamos democratizando el crédito hipotecario. Conoce nuestros valores y a los integrantes de nuestro equipo.';
				break;
			}
			case 'como-funciona': {
				_meta.title = 'Kredi | ¿Cómo es el proceso para obtener tu crédito hipotecario?';
				_meta.titleAlt = 'Kredi | ¿Cómo es el proceso para obtener tu crédito hipotecario?';
				_meta.description = 'Nuestro proceso está dividido en tres etapas. Obtener un crédito con nosotros es muy sencillo. Aquí te mostramos porqué';
				break;
			}
			case 'creditos': {
				_meta.title = 'Kredi | ¿Qué créditos ofrecemos y cuales son sus características?';
				_meta.titleAlt = 'Kredi | ¿Qué créditos ofrecemos y cuales son sus características?';
				_meta.description = 'Tanto si deseas comprar una casa o departamento, cómo si ya tienes una hipoteca y deseas pagar menos, tenemos la solución.';
				break;
			}
		}
	}
	return _meta;
}