import { Card, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Posts.styles";
import React from "react";
import { Link } from "../../../components/Link";

interface PostsProps {
	data: any[]
	tags: any[]
	tagSelected?: string
}

export function Posts({ data, tags, tagSelected }: PostsProps) {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid item >
				<Typography className={classes.title}>{tagSelected || "Inmobiliario"}</Typography>
			</Grid>
			<Grid container className={classes.containerPosts}>
				{
					data.map((post: any) => (
						<Grid item>
							<Link to={`/blog/${post.slug}`}>
								<Card className={classes.card}>
									<Grid container>
										<Grid item>
											<img
												src={post.featuredImage}
												alt={post.featuredImageAltText}
												className={classes.featuredImage}
											/>
										</Grid>
										<Grid item className={classes.informationContainer}>
											<Typography className={classes.tag}>{tags.find((tag: any) => tag.id === String(post.tagIds[0]))?.name}</Typography>
											<Typography className={classes.titlePost}>{post.name}</Typography>
											<Typography className={classes.description}> <span dangerouslySetInnerHTML={{ __html: post.postSummary }} /> </Typography>
											<Grid container className={classes.readContainer} justifyContent="flex-end">
												<Typography className={classes.tag}>Leer más <img width={14} src="/assets/images/arrow_right.svg" alt="" /> </Typography>

											</Grid>
										</Grid>
									</Grid>
								</Card>
							</Link>
						</Grid>
					))
				}
			</Grid>
		</Grid>
	)
}