
import React, { MouseEventHandler } from 'react'
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core'
import clsx from 'clsx'
import { Fragment } from 'react'
import { useStyles } from './Button.styles'
import { Link } from '../..'
import { segment as Segment } from '../../../helpers'

export interface ButtonProps extends MuiButtonProps {
	to?: string
	useNativeAnchor?: boolean
	target?: string;
}
export function Button({ children, className, to, onClick, useNativeAnchor, target, ...rest }: ButtonProps) {
	const classes = useStyles()
	const Wrapper = to ? Link : Fragment
	const wrapperProps = to ? { to: to } : {}

	function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
		Segment.track("Clicked", "Button", {
			from: window.name,
			to,
			label: typeof (children) === 'string' ? children : 'Button'
		})
		if (onClick) {
			onClick(event)
		}
	}

	if (useNativeAnchor) {
		return (
			<MuiButton
				disableElevation
				href={to}
				//@ts-ignore
				target={target || "_blank"}
				{...rest}
				onClick={handleClick}
				className={clsx(classes.button, className)}
				classes={{ disabled: classes.disabled }}
			>
				{children}
			</MuiButton>
		)
	}

	return (
		//@ts-ignore
		<Wrapper {...wrapperProps}>
			<MuiButton
				disableElevation
				{...rest}
				onClick={handleClick}
				className={clsx(classes.button, className)}
				classes={{ disabled: classes.disabled }}
			>
				{children}
			</MuiButton>
		</Wrapper>
	)
}