import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.primary.main,
	},
	rootAlternative: {
		backgroundColor: "#F9FAFC"
	},

	title: {
		fontWeight: 'bold',
		fontSize: 50,
		lineHeight: "63px",
		color: "white",
		marginTop: 159,
		marginBottom: 32,
		padding: '0 65px',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: 32,
			lineHeight: "40px",
			marginTop: 124,
			padding: '0 16px'


		}
	},
	titleAlternative: {
		color: theme.palette.primary.main,
	},
	buttonsContainer: {
		gap: 24,
		marginBottom: 160,
		[theme.breakpoints.down('sm')]: {
			marginBottom: 124,
		}
	},
	apply: {
		color: 'white',
		borderColor: 'white',
		"&:hover": {
			background: 'white !important',
			color: theme.palette.primary.main,
			boxShadow: '0px 16px 24px rgb(206 224 216 / 23%), 0px 8px 10px rgb(29 170 108 / 26%) !important'
		}
	},
	applyAlternative: {
		color: "white",
		borderColor: 'white',
		background: theme.palette.primary.main,
		"&:hover": {
			background: `${theme.palette.primary.main} !important`,
			color: "white",
			boxShadow: '0px 16px 24px rgb(206 224 216 / 23%), 0px 8px 10px rgb(29 170 108 / 26%) !important'
		}
	},
	creditQuotation: {
		backgroundColor: 'white',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main} !important`,
		padding: "14px 20px",
		"&:hover": {
			background: theme.palette.primary.main + '!important',
			color: "white",
			border: `1px solid white !important`,
		}
	},
	creditQuotationAlternative: {
		backgroundColor: 'white',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main} !important`,
		padding: "14px 20px",
		"&:hover": {
			background: 'white !important',
			color: theme.palette.primary.main,
			border: `1px solid ${theme.palette.primary.main} !important`,

		}
	}
}))