import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "40px 70px",
		gap: 24
	},
	tag: {
		border: "1px solid #CFD5E2",
		padding: "6px 21px",
		borderRadius: 30,
		"&:hover": {
			background: theme.palette.primary.main,
			border: '1px solid transparent',
			cursor: 'pointer',
			"& span": {
				color: "#fff"
			}
		}
	}
}))