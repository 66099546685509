import React from "react";
import MuiMenu, { MenuProps as MuiMenuProps } from "@material-ui/core/Menu";
import { useStyles } from "./Menu.styles";

export interface MenuProps extends MuiMenuProps {
    horizontalOrigin?: "left" | "center" | "right";
}

export function Menu({ anchorEl, onClose, open, children, PaperProps, horizontalOrigin = 'left', ...rest }: MenuProps) {
    const classes = useStyles();
    //@ts-ignore
    const { style, ...restPaperProps } = PaperProps || {};

    return (
        <MuiMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            elevation={0}
            getContentAnchorEl={null}
            disablePortal
            className={classes.menu}
            disableAutoFocusItem
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: horizontalOrigin,
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: horizontalOrigin,
            }}
            PaperProps={{
                elevation: 0,
                style: {
                    boxShadow: '0px 9px 12px rgba(57, 74, 97, 0.14), 0px 3px 16px rgba(57, 74, 97, 0.12), 0px 5px 6px rgba(57, 74, 97, 0.2)',
                    borderRadius: 4,
                    ...style
                },
                ...restPaperProps
            }}
            {...rest}
        >
            {children}
        </MuiMenu>
    )
}