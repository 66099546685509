import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	item: {
		fontWeight: "bold",
		fontSize: 16,
		lineHeight: "24px",
		letterSpacing: "0.15px",

	}
}))