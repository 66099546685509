import { Overrides } from "@material-ui/core/styles/overrides";
//@ts-ignore
import tinycolor from "tinycolor2";

export function overrides(primaryColor: string, secondaryColor: string): Overrides {
    return ({
        MuiCssBaseline: {
            '@global': {
                html: {
                    scrollBehavior: 'smooth'
                },
                body: {
                    backgroundColor: '#fff'
                },
            },
        },
        MuiTypography: {
            body1: {
                fontSize: 16,
                fontWeight: 400,
                letterSpacing: 0.5
            },
        },
        MuiButton: {
            disabled: {
                '&:hover': {
                    boxShadow: 'none !important'
                }
            },
            root: {
                borderRadius: 60,
                textTransform: 'none',
                letterSpacing: '0.005em'
            },
            sizeSmall: {
                fontSize: 14,
                padding: '8px 16px',
                height: 36
            },
            sizeLarge: {
                fontSize: 16,
                padding: '14px 40px',
                height: 52
            },
            contained: {
                boxShadow: 'none',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: primaryColor + ' !important',
                    // boxShadow: '0px 16px 24px rgba(61, 154, 65, 0.14), 0px 8px 10px rgba(44, 191, 128, 0.26) !important',
                    boxShadow: `0px 16px 24px ${tinycolor(primaryColor).setAlpha(.14)}, 0px 8px 10px ${tinycolor(primaryColor).setAlpha(.26)} !important`,
                },

            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: primaryColor + ' !important'
                }
            },
            outlined: {
                borderRadius: 60,
                padding: '14px 40px',
                textTransform: 'none',
                '&:hover': {
                    backgroundColor: '#fff !important',
                    // boxShadow: '0px 16px 24px rgba(61, 154, 65, 0.14), 0px 8px 10px rgba(44, 191, 128, 0.26)',
                    boxShadow: `0px 16px 24px ${tinycolor(primaryColor).setAlpha(.14)}, 0px 8px 10px ${tinycolor(primaryColor).setAlpha(.26)} !important`,
                },
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: 16,
                color: '#242C37 !important',
                fontWeight: 'bold',
                marginBottom: 16,
                lineHeight: 1.4,
                width: '95%'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: 50,
                ['@media (min-width: 600px)']: {
                    minHeight: 50
                }
            }
        },
        MuiLink: {
            root: {
                '& > svg': {
                    fill: '#242C37'
                }
            }
        }
    })
}