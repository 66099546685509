import { Grid } from "@material-ui/core"
import { useStyles } from "./Pagination.styles"
import React from 'react'
import clsx from "clsx"

interface PaginationProps{
	perPage: number;
	page: number;
	total: number;
	handleChangePage:(newPage:number) => void;

}

export function Pagination({perPage,page, total, handleChangePage}:PaginationProps){
	const classes = useStyles()

	function changePage(index:number){
		const teamContainer = document && document.getElementById("team")
		teamContainer?.scrollIntoView()
		handleChangePage(index + 1)
	}

	return(
		<Grid item container justifyContent="center" className={classes.root}>
			{
				Array(Math.ceil(total/perPage)).fill(null).map((_, index) => (
					<div 
						key={index}
						className={clsx(classes.dot, { [classes.dotActive]: index+1 === page })}
						onClick={() => changePage(index)} 
					/>
				))
			}
		</Grid>
	)
}