import { Card, Grid, Typography } from "@material-ui/core";
import { ArrowBackIos as ArrowBackIcon, Gif } from "@material-ui/icons";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import React from "react";
import { Button } from "../../";
import { useStyles } from "./HighlightsPosts.styles";

interface HighlightsPostsProps {
	posts: any[],
	tags: any[]
}

export function HighlightsPosts({ posts, tags }: HighlightsPostsProps) {
	const classes = useStyles()
	console.log(posts)
	return (
		<Grid container className={classes.root}>
			{
				posts?.length > 0 && (
					<Grid item>
						<img src={posts[0].featuredImage} alt={posts[0].featuredImageAltText} />
						<Typography className={classes.tag}>{tags.find((tag: any) => tag.id === String(posts[0].tagIds[0]))?.name}</Typography>
						<Typography>{posts[0].name}</Typography>
						<Typography><span dangerouslySetInnerHTML={{ __html: posts[0].postSummary }}></span></Typography>
					</Grid>
				)
			}

		</Grid>
	)
}