import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { useGetSite } from './useGetSite'
import Helmet from 'react-helmet';
import { getMetaData, MetaData } from './SEO.helpers';

export interface SEOProps {
	title?: string,
	titleAlt?: string,
	description?: string,
	banner?: string,
	pathname?: string,
	article?: boolean,
	keywords?: string,
	location: Location,
}


export function SEO({ title, titleAlt, description, banner, article, pathname, keywords, location }: SEOProps) {
	const {
		site: {
			buildTime,
			siteMetadata: {
				defaultTitle,
				defaultTitleAlt,
				shortName,
				author,
				siteLanguage,
				logo,
				siteUrl,
				pathPrefix,
				defaultDescription,
				defaultBanner,
				twitter,
				defaultKeywords,
			},
		} } = useGetSite()

	const seo: MetaData = {
		title: title || defaultTitle,
		titleAlt: titleAlt || defaultTitleAlt,
		description: description || defaultDescription,
		image: `${siteUrl}${banner || defaultBanner}`,
		url: `${siteUrl}${pathname || '/'}`,
		keywords: keywords || defaultKeywords
	};

	const meta = getMetaData(location.pathname, seo);
	const realPrefix = pathPrefix === '/' ? '' : pathPrefix;

	let schemaOrgJSONLD = [
		{
			'@context': 'http://schema.org',
			'@type': 'WebSite',
			'@id': siteUrl,
			url: siteUrl,
			name: meta.title,
			alternateName: meta.titleAlt,
		},
	];

	return (
		<Helmet title={meta.title}>
			<html lang={siteLanguage} />
			<meta name="description" content={meta.description} />
			<meta name="keywords" content={meta.keywords} />
			<meta name="image" content={meta.image} />
			<meta name="apple-mobile-web-app-title" content={shortName} />
			<meta name="application-name" content={shortName} />
			<script type="application/ld+json">
				{JSON.stringify(schemaOrgJSONLD)}
			</script>


			{/* OpenGraph  */}
			<meta property="og:url" content={meta.url} />
			<meta property="og:type" content={article ? 'article' : undefined} />
			<meta property="og:title" content={meta.title} />
			<meta property="og:description" content={meta.description} />
			<meta property="og:image" content={meta.image} />

			{/* Twitter Card */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:creator" content={twitter} />
			<meta name="twitter:title" content={meta.title} />
			<meta name="twitter:description" content={meta.description} />
			<meta name="twitter:image" content={meta.image} />

			{/* Facebook */}
			<meta name="facebook-domain-verification" content="yh2pziloe3l2jl2qp0r5n5tpwsf2ld" />

		</Helmet>
	)
}