import React from 'react';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { overrides } from './overrides';

const defaultPrimaryColor = '#1DAA6C';
const defaultSecondaryColor = '#2CC1A6';
const textColor = '#242C37'

function createTheme() {
	const primaryColor = defaultPrimaryColor;
	const secondaryColor = defaultSecondaryColor;

	return createMuiTheme({
		palette: {
			primary: {
				main: primaryColor,
				contrastText: '#fff'
			},
			secondary: {
				main: secondaryColor,
				contrastText: '#ffcc00',
			},

			// Used by `getContrastText()` to maximize the contrast between
			// the background and the text.
			contrastThreshold: 3,
			// Used by the functions below to shift a color's luminance by approximately
			// two indexes within its tonal palette.
			// E.g., shift from Red 500 to Red 300 or Red 700.
			tonalOffset: 0.2,
		},
		typography: {
			fontFamily: `${['Circular Std', 'Roboto'].join(',')} !important`,
			body1: {
				color: textColor
			},
		},
		overrides: overrides(primaryColor, secondaryColor),
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 1024,
				lg: 1280,
				xl: 1920,
			}
		}
	});
}

export function Theme({ children }: { children: React.ReactChild | React.ReactChild[] }) {
	return (
		<ThemeProvider theme={createTheme()}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
}
