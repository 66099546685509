import React, { Fragment } from "react";
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useStyles } from "./IconButton.styles";
import clsx from "clsx";

export interface IconButtonProps extends MuiIconButtonProps {
	variant?: 'standard' | 'outlined';
	tooltip?: string;
}

export function IconButton({
	variant = 'standard',
	children,
	className,
	tooltip,
	...rest
}: IconButtonProps) {
	const classes = useStyles();
	const Wrapper = tooltip ? Tooltip : Fragment;
	const wrapperProps = tooltip ? { title: tooltip || '' } : {}

	return (
		//@ts-ignore
		<Wrapper {...wrapperProps}>
			<MuiIconButton
				className={clsx(classes.iconButton, className, {
					[classes.outlinedPrimary]: variant === 'outlined' && rest.color === 'primary',
					[classes.outlinedDisabled]: variant === 'outlined' && rest.disabled,
					[classes.disabled]: rest.disabled
				})}
				{...rest}
			>
				{children}
			</MuiIconButton>
		</Wrapper>
	)
}