import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./Hero.styles";

export function HeroPost({ title, created, author, featuredImage, featuredImageAltText }: any) {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid item className={classes.informationContainer}>
				<Typography color="primary" className={classes.title}>{title}</Typography>
				<Typography className={classes.dateAndAuthorContainer}>
					<span className={classes.dateAndAuthor}>{new Date(created).toLocaleDateString()}</span>
					<span style={{ marginLeft: 4, marginRight: 4 }} > | </span>
					<span className={classes.dateAndAuthor}>{author}</span>
				</Typography>
				<Grid item container direction="row" className={classes.socialLinkContainer} >
					<Grid item>
						<a target={"_blank"} href="https://www.facebook.com/kredi.mx">
							<div className={classes.socialLink} >
								<img width={15} height={15} src="/assets/images/rrss_share.svg" alt="Compartir" />
							</div>
						</a>
					</Grid>
					<Grid item>
						<a target={"_blank"} href="https://www.facebook.com/kredi.mx">
							<div className={classes.socialLink} >
								<img width={16} height={16} src="/assets/images/facebook_icon.svg" alt="Logo de Facebook" />
							</div>
						</a>
					</Grid>
					<Grid item>
						<a target={"_blank"} href="https://www.linkedin.com/company/kredimx/">
							<div className={classes.socialLink} >
								<img width={14} height={14} src="/assets/images/linkendin_icon.svg" alt="Logo de Linkedin" />
							</div>
						</a>
					</Grid>
					<Grid item>
						<a target={"_blank"} href="https://twitter.com/kredimx">
							<div className={classes.socialLink}>
								<img width={14} height={14} src="/assets/images/twitter_icon.svg" alt="Logo de Twitter" />
							</div>
						</a>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<img src={featuredImage} alt={featuredImageAltText} />
			</Grid>
		</Grid>
	)
}