
declare global {
    interface Window { 
        analytics: any; 
        belvoSDK: any;
    }
}

export type TrackingActionType =
    | 'Clicked'
    | 'Viewed'
    | 'Created'
    | 'Updated'
    | 'Deleted'
    | 'Logout'
    | 'Continued'
    | 'Sent'


function track(action: TrackingActionType, from: string, properties?: any) {
    if(window.analytics) {
        const event = `${action} ${from}`;
        window.analytics.track(event, properties);
    }
    // console.log("Event", `${resource} ${action}`, properties);
}

export const segment = {
	track
}