import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F9FAFC"
	},
	title: {
		fontSize: 50,
		lineHeight: "64px",
		fontWeight: "bold",
		marginTop: 78,
		marginBottom: 60,
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			fontSize: 32,
			lineHeight: "40px",
			padding: '0 16px'
		}
	},
	FAQColumnsContainer: {
		padding: '0 68px',
		paddingBottom: 90,
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			padding: ' 0 16px',
			marginBottom: 128,
		}
	},
	FAQColumn: {
		gap: 24,
	},
	FAQItem: {
		//gap: 8,
	},
	FAQSm: {
		[theme.breakpoints.up(1280)]: {
			display: 'none'
		}
	},
	question: {
		fontSize: 18,
		lineHeight: '23px',
		fontWeight: 'bold',
		marginBottom: 8,
	},
	answer: {
		fontSize: 16,
		lineHeight: "24px",
		maxWidth: "100%"
	},
	list: {
		paddingInlineStart: 20,
		margin: 0,
		"& li": {
			color: theme.palette.primary.main
		}
	},
	FAQColumnAlt: {
		display: 'grid',
		gridTemplateRows: "minmax(min-content, 85px) 85px repeat(1, auto)",
		gap: 25,
		[theme.breakpoints.between(1301, 1811)]: {
			gridTemplateRows: "minmax(min-content, 103px) 100px repeat(1, auto)",

		},
		[theme.breakpoints.down(1301)]: {
			gridTemplateRows: "minmax(min-content, 125px) 100px repeat(1, auto)",

		}
	},
	krediProccessQ: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,

	}

}))