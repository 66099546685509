import React, { ReactElement, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { Add, Minimize } from "@material-ui/icons";
import { useStyles } from './FAQAccordionItem.styles';

interface FAQAccordionProps {
	question: string;
	children: ReactElement | ReactElement[] | string
}

export function FAQAccordion({ children, question }: FAQAccordionProps) {
	const classes = useStyles()
	const [isExpanded, setIsExpanded] = useState(false)
	const handleExpanded = () => {
		setIsExpanded(!isExpanded);
	}

	return (
		<Accordion
			elevation={0}
			className={classes.accordion}
		>
			<AccordionSummary
				expandIcon={!isExpanded ? <Add color='primary' /> : <Minimize color='primary' />}
				className={classes.summary}
				onClick={handleExpanded}
			>
				<Typography className={classes.question}>{question}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{children}
			</AccordionDetails>
		</Accordion>
	)
}