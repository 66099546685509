import { MessagePage } from "..";
import { useStyles } from "./styles";
import React from 'react'

export function NotFound() {
	const classes = useStyles();
	return (
		<MessagePage
			title="Página no encontrada"
			message="Lo sentimos, no podemos encontrar la página que estás buscando."
			icon={"/assets/images/404.svg"}
			containerProps={{
				className: classes.dataContainer
			}}
		/>
	)
}