import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
	outlinedPrimary: {
		border: '1px solid ' + theme.palette.primary.main,
		'& svg': {
			fill: theme.palette.primary.main
		}
	},
	iconButton: {
		height: 36,
		width: 36,
		'& svg': {
			height: 16
		}
	},
	outlinedDisabled: {
		border: '1px solid rgba(0, 0, 0, 0.26)',
	},
	disabled: {
		'& svg': {
			fill: 'rgba(0, 0, 0, 0.26)'
		}
	}
}));