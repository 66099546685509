import React, { ReactElement } from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import clsx from "clsx";
import { useStyles } from "./Link.styles";
import { segment as Segment} from "../../helpers";

interface LinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
	children: ReactElement[] | ReactElement | string
}

export function Link({ to, children, className, ...rest }: LinkProps) {
	const classes = useStyles()
	function handleClick(){
		Segment.track("Clicked", "Link", {
			from: window.name,
			to,
			label: typeof (children) === 'string' ? children : 'Link'
		})
	}
	return (
		<GatsbyLink 
			to={to} 
			onClick={handleClick} 
			className={ clsx(classes.link, className) }
			{...rest}>
			{children}
		</GatsbyLink>
	)
}