import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./TestimonialItem.styles";
import clsx from "clsx";
import { motion } from "framer-motion";

interface TestimonialItemProps {
	text: string
	logo: string
	logoAlt: string
}

export function TestimonialItem({ text, logo, logoAlt }: TestimonialItemProps) {
	const classes = useStyles()
	return (
		<a className={classes.link} target={"_blank"} href="https://www.forbes.com.mx/kredi-marketplace-creditos-hipotecarios-2-mdd-mexico/amp/">
			<Grid
				item
				container
				className={classes.root}
				justifyContent="center"
				// data-sal="slide-left"
				// data-sal-delay="0"
				// data-sal-duration="1000"
				// data-sal-easing="ease"

				initial={{ opacity: 0, x: 100 }}
				transition={{ duration: 1.1, }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
				component={motion.div}
			>

				<Grid
					item
					className={classes.quotationMarkContainer}

				>
					<img className={classes.quotationMark} src="/assets/images/comillas.svg" alt="" />
				</Grid>

				<Grid item className={classes.textContainer}>
					<Typography
						className={classes.text}
					>
						<img className={clsx(classes.quotationMark, classes.quotationMarkSmall)} src="/assets/images/comillas.svg" alt="" />
						{text}
						<img className={clsx(classes.quotationInverted, classes.quotationMarkSmall)} src="/assets/images/comillas.svg" alt="" />
					</Typography>
					<img
						className={classes.logo}
						src={logo}
						alt={logoAlt}

					/>
				</Grid>
				<Grid
					item
					className={classes.quotationMarkContainer}

				>
					<img className={classes.quotationInverted} src="/assets/images/comillas.svg" alt="" />
				</Grid>

			</Grid>
		</a>
	)
}