import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(( )  => ({
    menu: {
        '& .MuiList-padding': {
            padding: 0,
            '& a': {
                textDecoration: 'none'
            }
        }
    },
}));