import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	link: {
		color: "#000",
		textDecoration: 'none',
		"&:visited":{
			color: "#000",
		},
		"&:active": {
			color: "#000",
		}
	}
}))