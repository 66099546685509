import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Footer.styles";
import { Button } from "..";
import clsx from "clsx";
import { motion } from "framer-motion";

interface FooterProps {
	title: string
	alternative?: boolean
}

export function Footer({ title, alternative }: FooterProps) {
	const classes = useStyles()
	return (
		<Grid
			container
			className={clsx(classes.root, { [classes.rootAlternative]: alternative })}
			justifyContent="center"

		>
			<motion.div
				initial={{ opacity: 0, x: 140 }}
				transition={{ duration: 1.6, type: "tween" }}
				whileInView={{ opacity: 1, x: 0 }}
				viewport={{ once: true }}
			//component={motion.div}
			>
				<Grid
					item
					container
					justifyContent="center"

				>
					<Typography
						className={clsx(classes.title, { [classes.titleAlternative]: alternative })}
						component="h2"
					>
						{title}
					</Typography>
				</Grid>
				<Grid
					item
					container
					justifyContent="center"
					// initial={{ opacity: 0, y: 100 }}
					// transition={{ duration: 1.5 }}
					// whileInView={{ opacity: 1, y: 0 }}
					// viewport={{ once: true }}
					// component={motion.div}
					className={classes.buttonsContainer}>
					<Button
						className={clsx(classes.apply, { [classes.applyAlternative]: alternative })}
						variant="outlined"
						useNativeAnchor
						target="_self"
						to={`${process.env.GATSBY_KREDI_APP_BASE_URL}`}
					>
						Solicitar ahora
					</Button>


				</Grid>
			</motion.div>

		</Grid >
	)
}