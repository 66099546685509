import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "170px 72px 130px 72px",
		[theme.breakpoints.down('sm')]:{
			padding: '0 16px',
			minHeight: 480,
		}

	},
	button:{
		width: 40,
		height: 40,
		borderRadius: "50%",
		background: "rgba(207,213,226,0.4)",
		minWidth: 39,
		maxWidth: 40,
		"& svg":{
			fontSize: 16
		},
		[theme.breakpoints.down('sm')]:{
			display: 'none'
		}
	},
	buttonNext:{
		transform: "rotate(-180deg)",
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	}
}))