import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundImage: `url("/assets/images/hero-blog-background.png")`,
		backgroundSize: 'cover',
		backgroundColor: '#F7FCF9',
		minHeight: 525,
		padding: '138px 362px',
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			padding: '306px 16px',
			backgroundImage: `url("/assets/images/hero-blog-background-mobile.svg")`,
			height: 'calc(100vh - 50px)'
		}
	},
	title: {
		fontSize: 60,
		lineHeight: "76px",
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: '25px',
		[theme.breakpoints.down('xs')]: {
			fontSize: 40,
			lineHeight: "48px",
		}
	},
	subtitle: {
		fontSize: 24,
		lineHeight: "24px",
		textAlign: 'center',
		letterSpacing: '0.15px',
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		}
	}
}))