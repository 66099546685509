import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	question: {
		fontSize: 18,
		// lineHeight: '23px',
		fontWeight: 500,
		lineHeight: '1.5',
		letterSpacing: '0.5px',
		margin: '1rem 0'
	},
	summary: {
		padding: 0
	},
	answer: {
		fontSize: 16,
		lineHeight: "24px"
	},
	accordion: {
		background: '#F9FAFC',
		width: '100%'
	}
})) 