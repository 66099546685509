import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Footer.styles";
import React from "react";
import { Button } from "../..";

export function FooterPost() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid item className={classes.infoContainer}>
				<Typography className={classes.title}>¡Solicita tu crédito!</Typography>
				<Typography className={classes.description}>¿Qué esperas? ¡Inicia tu aplicación y solicita tu crédito hoy mismo!.</Typography>
				<Button
					variant="contained"
					color="primary"
					to={`${process.env.GATSBY_KREDI_APP_BASE_URL}/`}
					useNativeAnchor
					target="_self"
				>
					Solicitar ahora
				</Button>
			</Grid>
			<Grid item>
				<img className={classes.image} alt="" src="/assets/images/tu-credito.svg" />
			</Grid>
		</Grid>
	)
}