import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
    children: {
        display: 'flex',
        justifyContent: 'center',
        padding: 24
    },
    dataContainer:{
        justifyContent: 'center',
        marginTop: '0',
        padding: '0px 16px',
        "h3":{
            marginBottom: '8px'
        },
        "& p":{
            maxWidth: '809px'
        }
    }
}));