import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		gap: 7,
		marginBottom: 20,
	},
	dot:{
		width: 10,
		height: 10,
		background: '#CFD5E2',
		borderRadius: "50%",
		cursor: 'pointer'
	},
	dotActive:{
		background: theme.palette.primary.main
	}
})) 