import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		background: "rgba(117, 139, 183, 0.04)",
		padding: "80px 70px",
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			padding: "60px 16px",
			justifyContent: 'center',

		},
		[theme.breakpoints.up(1440)]: {

			justifyContent: 'center',

		}
	},
	infoContainer: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			marginBottom: 24

		}
	},
	title: {
		fontSize: 40,
		lineHeight: "150%",
		marginBottom: 16,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: 32,
			lineHeight: "40px",

		}

	},
	description: {
		fontSize: 24,
		lineHeight: "160%",
		marginBottom: 22,
		maxWidth: 580,
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: 18,
			lineHeight: "32x",
		}

	},
	image: {
		width: 407,
		heigth: 337,
		[theme.breakpoints.down('sm')]: {
			height: 239,
			width: 287,
		}

	}
}))