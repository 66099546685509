import { Grid, Typography, GridProps, TypographyProps } from "@material-ui/core";
import { useStyles } from "./MessagePage.styles";
import clsx from "clsx";
import React from 'react'

export interface MessagePageProps extends GridProps {
	containerProps?: GridProps;
	showBackgroundImages?: boolean;
	icon?: | string;
	title?: string;
	message?: string;
	messageContainerProps?: TypographyProps;
	children?: React.ReactChild | React.ReactChild[];
}

export function MessagePage({
	containerProps,
	showBackgroundImages,
	icon,
	title,
	message,
	children,
	messageContainerProps,
	className,
	...rest
}: MessagePageProps) {
	const classes = useStyles();
	const { className: containerClassName, ...restContainerProps } = containerProps || {};

	return (
		<Grid
			container
			className={clsx(classes.container, className)}
			{...rest}
		>
			<Grid className={clsx(classes.data, containerClassName)} {...restContainerProps}>
				{icon && <img src={icon} alt={title} />}
				{title && <Typography variant="h3" align="center"> {title} </Typography>}
				{message && <Typography dangerouslySetInnerHTML={{ __html: message }} {...messageContainerProps} />}
				{children}
			</Grid>
		</Grid>
	)
}