import React from "react";
import { Grid, GridProps } from "@material-ui/core";
import { Button } from "..";
import { ArrowBackIos as ArrowIcon } from "@material-ui/icons";
import { useStyles } from "./Testimonial.styles";
import clsx from "clsx";
import { TestimonialItem } from "./TestimonialItem";
interface TestimonialProps {
	containerProps?: GridProps
}
export function Testimonial({ containerProps }: TestimonialProps) {
	const classes = useStyles()
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			className={classes.root}
			{...containerProps}
		>
			{/* <Grid item>
				<Button className={classes.button}> 
					<ArrowIcon  />
				</Button>
			</Grid> */}
			<TestimonialItem
				text="Kredi, una compañía de tecnología financiera que tiene como objetivo ser un marketplace que permita a los mexicanos que hoy buscan casa, acceder al crédito que más convenga a sus necesidades."
				logo="/assets/images/forbes-logo.png"
				logoAlt="Logo de Forbes"
			/>
			{/* <Grid item>
				<Button className={classes.button}>
					<ArrowIcon className={classes.buttonNext} />
				</Button>
			</Grid> */}
		</Grid>
	)
}